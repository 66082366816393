@import "root";

/* ------ COLOURS -------*/
$color-beige: rgb(249, 247, 240);
$color-blue-black: rgb(39, 49, 56);
$color-yellow: rgb(247, 224, 53);
$box-shadow-standard: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
  0 6px 20px 2px rgba(0, 0, 0, 0.19);
$color-light-blue: rgb(255, 245, 245);
$color-light-pink: rgb(255, 253, 246);

/* ------ OWN STYLES -------*/

h1,
h2,
p {
  margin-top: 0px;
  margin-bottom: 0px;
}

html {
  background: #ffffff;
  font-size: 16px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
}

ul {
  padding-inline-start: 20px;
}

a {
  text-decoration: underline;
  color: black;
}

a:visited {
  color: black;
}

body {
  margin: 0;
}

nav {
  padding: 1em;
  padding-top: 1.5em;
  color: $color-blue-black;
  text-align: center;
}

#brand {
  text-decoration: none;
}

#brand > #title {
  font-weight: 900;
  color: rgb(39, 49, 56);
  text-transform: uppercase;
  text-align: center;
  font-size: 1.5em;
}

#logo {
  height: 50px;
}

#navlinks {
  margin-top: 1em;
}

#navlinks > a {
  text-decoration: none;
  color: $color-blue-black;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

nav > a.active {
  font-weight: bold;
}

nav > a:visited {
  color: rgb(39, 49, 56);
}

#callout {
  background: rgba(247, 224, 53, 0.3);
  color: rgba(243, 156, 18, 1);
  padding: 0.2em 1em;
  border-radius: 10px;
  text-align: center;
  margin-top: 1em;
}

p {
  line-height: 1.5rem;
}

li {
  line-height: 1.5rem;
}

.section {
  width: 100%;
  margin-top: 1em;
}

.center-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#writeup {
  padding-left: 1em;
  padding-right: 1em;
}

#introduction {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
}

#header > #title {
  font-weight: 300;
  font-size: 3em;
  min-width: 350px;
  text-align: center;
}

h2.description {
  text-align: center;
  font-weight: 300;
  color: rgb(39, 49, 56);

  margin-top: 1em;
  margin-bottom: 1em;
}

a#download-button {
  margin-top: 0em;
  background: rgb(247, 224, 53);
  font-weight: 700;
  color: rgb(39, 49, 56);
  width: 40%;
  border: 0;
  padding: 1em;
  font-size: 0.85em;
  border-radius: 5px;
  margin-bottom: 1em;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
}

a#buy-button {
  margin-top: 0em;
  background: rgb(39, 49, 56);
  font-weight: 700;
  color: rgb(247, 224, 53);
  width: 40%;
  border: 0;
  padding: 1em;
  font-size: 0.85em;
  border-radius: 5px;
  margin-bottom: 1em;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
}

div#captions {
  margin-top: 0.5em;
  margin-bottom: 3em;
}

.caption {
  font-size: 0.8rem;
  line-height: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

img#excel {
  height: 20px;
  margin-right: 0.1em;
}

img#powerpoint {
  height: 20px;
  margin-right: 0.3em;
}

img#apple {
  height: 20px;
  margin-right: 0.3em;
}

#demo {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1em;
  padding-bottom: 1em;
}

#demo > p#demo-title {
  padding-bottom: 1em;
  font-weight: 300;
  color: rgb(39, 49, 56);
  text-align: center;
  font-size: 1.4em;
}

#demo > #video {
  width: 100%;
  min-width: 300px;
  max-width: 600px;
  margin-bottom: 1em;
  // box-shadow: $box-shadow-standard;
}

iframe#big-video {
  display: none;
}

iframe {
  border-radius: 20px;
}

#demo > #demo-caption {
  text-align: center;
}

#call-to-action {
  display: flex;
  justify-content: space-between;
}

p#message {
  font-size: 0.8rem;
  padding: 0 1em;
  margin-bottom: 1em;
  border-radius: 5px;
  display: none;
}

p#message.success {
  background: rgba(39, 174, 96, 0.1);
  color: rgba(39, 174, 96, 1);
  display: block;
}

p#message.error {
  background: rgba(192, 57, 43, 0.2);
  color: rgba(192, 57, 43, 1);
  display: block;
}

input#email-address-input {
  padding: 0.8em;
  font-size: 0.85em;
  width: 220px;
  border: 0;
  border-bottom: 1px solid $color-blue-black;
  text-align: center;
}

input#email-address-input:focus {
  outline: none;
}

button#submit-button {
  background: rgb(247, 224, 53);
  font-weight: 700;
  color: rgb(39, 49, 56);
  border: 0;
  padding: 1em;
  font-size: 0.85em;
  border-radius: 5px;
  margin-bottom: 1em;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
}

#numbers {
  display: flex;
  flex-direction: row;
}

.figures {
  font-weight: 300;
  font-size: 3rem;
  text-align: center;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.figures > .units {
  font-size: 1rem;
}

.section-content {
  width: 90%;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
}

.section-content > h1 {
  padding-bottom: 1em;
  font-weight: 300;
  color: rgb(39, 49, 56);
  text-align: center;
  font-size: 2em;
  margin-top: 1.5em;
}

h1.section-header {
  font-weight: 300;
  color: rgb(39, 49, 56);
  text-align: center;
  font-size: 2em;
}

.features__description {
  text-align: left;
  color: rgb(39, 49, 56);
  max-width: 600px;
  margin-top: 1em;
  margin-bottom: 1em;
}

.section-text {
  max-width: 680px;
  text-align: left;
}

.section-text > ul {
  margin-top: 0em;
}
.section-text > ul > li {
  margin-bottom: 1em;
}

.section-text > ul > p {
  margin-bottom: 1em;
}

.section-text > p {
  margin-bottom: 1em;
}

.section-text > img.inline {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 6px;
  margin-bottom: 1em;
  height: 1.5em;
}

.section-content#future-features,
.section-content#issues {
  border-radius: 10px;
}

#feature-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

#feature-list > section {
  flex: 1;
  text-align: center;
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 2em;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0.25em 1em 2em 0px rgba(127, 127, 127, 0.1),
    -0.25em 1em 2em 0px rgba(127, 127, 127, 0.1);
  padding: 1.5em 1em;
}

#feature-list > section > .section-header {
  margin-top: 1em;
  margin-bottom: 1em;
}

#feature-list > section > img {
  height: 3.2em;
}

h1.section-header {
  font-size: 2em;
  padding-bottom: 0.8em;
}

#testimonial-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

#testimonial-list > section {
  flex: 1;
  text-align: center;
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 1.5em;
  max-width: 500px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0.25em 1em 2em 0px rgba(127, 127, 127, 0.1),
    -0.25em 1em 2em 0px rgba(127, 127, 127, 0.1);
  padding: 1.5em 1em;
  display: block;
}

#testimonial-ph {
  display: flex;
  flex-direction: column;
}

img.testimonial-screenshot {
  box-shadow: 0.25em 1em 2em 0px rgba(127, 127, 127, 0.1),
    -0.25em 1em 2em 0px rgba(127, 127, 127, 0.1);
  border-radius: 10px;
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 1.5em;
  padding: 1.5em 1em;
  box-sizing: border-box;
  max-width: 93%;
  display: none;
}

#testimonial-list > section > .section-header {
  margin-top: 1em;
}

.customer-logos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0px;
}

.customer-logos img {
  height: 30px;
  margin: 10px 30px;
}

img.standard-img {
  height: 4.5em;
}

a.back-to-top {
  font-size: 0.5em;
  color: black;
  text-decoration: none;
}

a.back-to-top:hover {
  font-weight: bold;
}

.faq-list p,
.faq-list img,
.faq-list ol,
.faq-list ul {
  display: none;
  margin: 1em 0px;
}

.faq-list .question {
  margin-bottom: 2em;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0.25em 1em 2em 0px rgba(127, 127, 127, 0.1),
    -0.25em 1em 2em 0px rgba(127, 127, 127, 0.1);
  padding: 1.5em 1em;
  margin-left: -1.5em;
  cursor: pointer;
}

.faq-list li {
  list-style: none;
}

.faq-list ol > li,
.faq-list ul > li {
  list-style: decimal;
}

.faq-list p.show,
.faq-list img.show,
.faq-list ol.show,
.faq-list ul.show {
  display: block;
}

footer {
  padding-top: 3em;
  padding-bottom: 3em;
  text-align: center;
  font-size: 0.85em;
}

#financial-modelling-review {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#financial-modelling-review img {
  width: 100%;
  box-shadow: 0.25em 1em 2em 0px rgba(127, 127, 127, 0.1),
    -0.25em 1em 2em 0px rgba(127, 127, 127, 0.1);
  border-radius: 10px;
  max-width: 600px;
  margin-bottom: 1em;
}

p#quote {
  font-size: 1.5em;
  text-align: center;
  margin-top: 1em;
}

p#quote-attribute {
  margin-top: 1em;
  text-align: center;
}

@media (min-width: 980px) {
  html {
    font-size: 16px;
  }

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #navlinks {
    margin-top: 0em;
  }

  #brand {
    display: flex;
    align-items: center;
  }

  #brand > #title {
    text-align: center;
    font-size: 1.5em;
    margin-left: 0.25em;
  }

  #header > #title {
    text-align: left;
  }

  h2.description {
    text-align: left;
  }

  #logo {
    height: 50px;
  }

  .section {
    // padding-top: 2em;
  }

  #header {
    align-items: center;
  }

  #introduction {
    flex-direction: row;
  }

  .container {
    padding-right: 4em;
    padding-left: 4em;
  }

  a#download-button {
    display: block;
    padding: 0.8em;
    max-width: 300px;
    width: 42%;
  }

  a#buy-button {
    display: block;
    padding: 0.8em;
    max-width: 300px;
    width: 42%;
  }

  div#introduction {
    display: flex;
    flex-direction: left;
    max-width: 1000px;
  }

  #demo {
    width: 600px;
    margin-left: 50px;
    background: none;
  }

  #demo > h2 {
    display: none;
  }

  #video {
    max-width: 100%;
  }

  iframe#big-video {
    display: block;
  }

  iframe#mobile-video {
    display: none;
  }

  #writeup {
    flex-grow: 1;
  }

  .caption {
    font-size: 0.8em;
  }

  #feature-list {
    flex-direction: row;
  }

  #testimonial-list {
    flex-direction: row;
    flex-wrap: wrap;
  }

  img.testimonial-screenshot {
    max-width: 800px;
    display: block;
  }
}

@media (max-width: 350px) {
  h1#title {
    font-size: 1.6em;
  }
}

.yellow-highlight {
  background: rgba(247, 224, 53, 0.5);
}

.yellow-highlight-fuzzy {
  text-shadow: 0.1em 0.1em 0.6em rgb(247, 224, 53),
    0.1em -0.1em 0.6em rgb(247, 224, 53), -0.1em -0.1em 0.6em rgb(247, 224, 53),
    -0.1em 0.1em 0.6em rgb(247, 224, 53);
}

.plain-page {
  max-width: 600px;
  margin: auto;
  width: 90%;
}

.plain-page h1 {
  text-align: center;
  font-weight: 300;
  margin-top: 1em;
}

.plain-page h2 {
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.plain-page p {
  margin-top: 1em;
  text-align: left;
}

.plain-page li {
  margin-bottom: 0.5em;
  text-align: left;
}

.plain-page img {
  width: 100%;
}

code.highlighter-rouge {
  font-size: 0.8em;
  color: #c0392b;
  background: #ecf0f1;
  padding: 0em 0.4em;
}

.key {
  color: #3a3a3a;
  background: #f7f7f7;
  border-top: 1px solid #f5f5f5;
  box-shadow: inset 0 -2px 0 #aaaaaa, inset 0px 1px 1px -1px #fff,
    0px 1px 1px 0px #7a7a7a;
  text-transform: capitalize;
  padding: 0.25em 0.75em;
  font-size: 0.75em;
  border-radius: 5px;
  margin-left: 2px;
  margin-right: 2px;
  display: inline-block;
}
